* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Poppins";
}

body {
  background-color: #fdfdfd;
}

.layout {
  padding: 30px;
}

.mt-5 {
  margin-top: 50px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-2 {
  margin-top: 20px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.p-3 {
  padding: 30px;
}

.pb-5 {
  padding-bottom: 50px;
}

.py {
  padding: 20px 0px;
}

table {
  overflow: auto !important;
}
