.desktop-navbar {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #f7fafc;
  padding-top: 20px;
  padding-bottom: 20px;
  border-right: 1px solid #ecececdd;
}

.mobile-navbar {
  width: 100vw;
  height: 100px;
  background-color: #f7fafc;
  display: flex !important;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
  position: fixed !important;
  top: 0;
  z-index: 1000;
}

.mobile-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mobile-brand h2 {
  color: #3d3d3d;
  padding-left: 15px;
  text-transform: uppercase;
}

.mobile-navbar img {
  width: 60px;
  border-radius: 50%;
}

.desktop-navbar img {
  width: 100%;
  border-radius: 15px;
}

.menu-list-item {
  margin: 13px 0;
}

.menu-list-item a {
  display: inline-block;
  font-family: "Poppins";
  word-spacing: 3px;
  border-radius: 15px;
  padding: 15px 15px;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #575757;
  font-weight: 500;
  letter-spacing: -0.6px;
  font-size: 1rem;
  text-transform: capitalize;
  transition: background 0.3s;
}

.menu-list-item a span {
  padding-left: 10px;
}

.logout {
  background-color: #ff607c !important;
}

.menu-list-item a:hover {
  background-color: #ffcfc2;
  color: #3d3d3d;
}

.logout:hover {
  background-color: #f14363 !important;
}
.active-list-item a {
  background-color: #ffcfc2;
  color: #3d3d3d;
  border-radius: 15px;
}

.mobile-container {
  position: fixed;
  top: 100px;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background-color: #f9fbff;
  padding: 0 20px;
}

@media screen and (max-width: 900px) {
  .desktop-navbar {
    display: none;
  }
  .mobile-navbar {
    display: block;
  }
  .layout {
    padding-top: 100px;
  }
}

@media screen and (min-width: 900px) {
  .desktop-navbar {
    display: block;
  }
  .mobile-navbar {
    display: none !important;
  }
  .mobile-container {
    display: none !important;
  }
}
